import React, { useMemo } from 'react'
import { CheckmarkCircleIcon, ErrorIcon, Flex, LinkExternal, Text, Modal, Button } from '@duhd4h/global-uikit'
import { useActiveWeb3React } from 'hooks'
import { getBscScanLink } from 'utils'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import Loader from 'components/Loader'
import styled, { ThemeContext } from 'styled-components'

const ModalStyles = styled(Modal)`
  background: #19181d;
  border: 0px;
  h2 {
    color: #ffffff;
  }
  #close_dialog {
    background: #121117;
    border-radius: 10px;
  }
`

const ButtonGlobalStyle = styled(Button)`
  background: #cafc4f;
  border-radius: 10px;
  color: black;
`

type RecentTransactionsModalProps = {
  onDismiss?: () => void
  translateString: (translationId: number, fallback: string) => string
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const getRowStatus = (sortedRecentTransaction: TransactionDetails) => {
  const { hash, receipt } = sortedRecentTransaction

  if (!hash) {
    return { icon: <Loader />, color: 'text' }
  }

  if (hash && receipt?.status === 1) {
    return { icon: <CheckmarkCircleIcon color="success" />, color: 'success' }
  }

  return { icon: <ErrorIcon color="failure" />, color: 'failure' }
}

const RecentTransactionsModal = ({ onDismiss = defaultOnDismiss, translateString }: RecentTransactionsModalProps) => {
  const { account, chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  // Logic taken from Web3Status/index.tsx line 175
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  return (
    <ModalStyles color="white" title={translateString(1202, '')} onDismiss={onDismiss}>
      <h2 style={{ position: 'absolute', top: '42%', color: 'WHITE', fontSize: '24px' }}>Recent Transactions</h2>
      {!account && (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Text color="#ffffff" mb="8px" bold>
            Please connect your wallet to view your recent transactions
          </Text>
          <ButtonGlobalStyle variant="tertiary" scale="sm" onClick={onDismiss}>
            Close
          </ButtonGlobalStyle>
        </Flex>
      )}
      {account && chainId && sortedRecentTransactions.length === 0 && (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Text color="white" mb="8px" bold>
            No recent transactions
          </Text>
          <ButtonGlobalStyle variant="tertiary" scale="sm" onClick={onDismiss}>
            Close
          </ButtonGlobalStyle>
        </Flex>
      )}
      {account &&
        chainId &&
        sortedRecentTransactions.map((sortedRecentTransaction) => {
          const { hash, summary } = sortedRecentTransaction
          const { icon, color } = getRowStatus(sortedRecentTransaction)

          return (
            <>
              <Flex key={hash} alignItems="center" justifyContent="space-between" mb="4px">
                <LinkExternal href={getBscScanLink(chainId, hash, 'transaction')} color={color}>
                  {summary ?? hash}
                </LinkExternal>
                {icon}
              </Flex>
            </>
          )
        })}
    </ModalStyles>
  )
}

export default RecentTransactionsModal
