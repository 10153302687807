import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from 'dexynth-toolkit'
import useI18n from 'hooks/useI18n'

const StyledNav = styled.div``

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  const currentPath = window.location.href

  console.log(currentPath)

  return (
    <div
      style={{
        borderRadius: '3px',
        marginBottom: '10px',
        display: 'flex',
        width: '30%',
        height: '50px',
        backgroundColor: '#19181D',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0px 10px',
      }}
    >
      <div
        style={{
          width: '49%',
          backgroundColor: currentPath.includes('/swap') ? '#CAFC4F' : '#3C3D43',
          height: '30px',
        }}
      >
        <ButtonMenuItem style={{ width: '100%', textAlign: 'center' }} to="/swap" as={Link}>
          <p
            style={{
              color: currentPath.includes('/swap') ? '#121117' : '#808080',
              padding: '0',
              borderRadius: '3px',
              height: '30px',
              lineHeight: '0px',
            }}
          >
            Swap
          </p>
        </ButtonMenuItem>
      </div>
      <div
        style={{
          width: '45%',

          backgroundColor: currentPath.includes('/pool') ? '#CAFC4F' : '#3C3D43',
          height: '30px',
        }}
      >
        <ButtonMenuItem style={{ width: '100%', textAlign: 'center' }} id="pool-nav-link" to="/pool" as={Link}>
          <p
            style={{
              color: currentPath.includes('/pool') ? '#121117' : '#808080',
              padding: '0',
              borderRadius: '3px',
              height: '30px',
              lineHeight: '0px',
            }}
          >
            Liquidity
          </p>
        </ButtonMenuItem>
      </div>
    </div>
  )
}

export default Nav
