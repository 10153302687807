import React from 'react'
import styled from 'styled-components'
import { Card, GradientBorderBox } from '@duhd4h/global-uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  width: 100%;
  width: 100%;
  z-index: 5;
  background: #19181d;
`

export const GradientBorderBoxWrapper = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  z-index: 0;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return (
    <GradientBorderBoxWrapper>
      <BodyWrapper>{children}</BodyWrapper>
    </GradientBorderBoxWrapper>
  )
}
