import React from 'react'
import { Modal } from '@duhd4h/global-uikit'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'
import styled from 'styled-components'

const ModalStyles = styled(Modal)`
  background: #19181d;
  border: 0px;
  border-bottom: 0 !important;
  h2 {
    color: white;
  }
  #close_dialog {
    background: #121117;
    border-radius: 10px;
  }
  button {
    background: #121117;
    border-radius: 16px;
    border: 0px;
    box-shadow: none;
  }
  button.cTsiiO {
    background: #cafc4f;
  }
  input {
    background: #121117;
    border-radius: 16px;
    color: black;
    box-shadow: none;
  }
`

type SettingsModalProps = {
  onDismiss?: () => void
  translateString: (translationId: number, fallback: string) => string
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal = ({ onDismiss = defaultOnDismiss, translateString }: SettingsModalProps) => {
  return (
    <ModalStyles title={translateString(1200, '')} onDismiss={onDismiss}>
      <h2 style={{ position: 'absolute', top: '35%', color: 'white', fontSize: '24px' }}>Settings</h2>
      <SlippageToleranceSetting translateString={translateString} />
      <TransactionDeadlineSetting translateString={translateString} />
    </ModalStyles>
  )
}

export default SettingsModal

const Top = styled.div`
  position: relative;
  &:nth-child() {
    position: absolute;
    z-index: 3 !important;
  }
`
