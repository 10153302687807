import styled from 'styled-components'

const Card = styled.div<any>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
`
export default Card

export const LightCard = styled(Card)`
  /* border: 1px solid ${({ theme }) => theme.colors.invertedContrast}; */
  background-color: #121117; ;
`

export const GreyCard = styled(Card)`
  background-color: #121117;
  color: #ffffff !important;
`
