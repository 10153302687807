import { ChainId } from '@dexynth/dexynth-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb', // TODO
  [ChainId.BSCTESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A',
  [ChainId.OPBNBTESTNET]: '0xfD431633f5c1bb6869c9D198740c33C9730CAF01', //TODO
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
